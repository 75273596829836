import { useState } from 'react';
import Image from 'next/image';
import { Card, CardContent } from '@/components/components/ui/card';
import { Input } from '@/components/components/ui/input';
import { Tabs, TabsList, TabsTrigger } from '@/components/components/ui/tabs';
import cx from 'classnames';

const videoCategories = {
    'all-access': [
        {
            title: 'Popular Fractions Videos',
            videos: [
                {
                    id: 1,
                    title: 'Solving Two-Step Equations'
                },
                {
                    id: 2,
                    title: 'Functions'
                },
                {
                    id: 3,
                    title: 'Definitions'
                },
                {
                    id: 4,
                    title: 'Solving Two-Step Equations'
                }
            ]
        },
        {
            title: 'Popular Equations Videos',
            videos: [
                {
                    id: 5,
                    title: 'Multiplying and Dividing Decimals'
                },
                {
                    id: 6,
                    title: 'Solving Two-Step Equations'
                },
                {
                    id: 7,
                    title: 'Solving Two-Step Equations'
                },
                {
                    id: 8,
                    title: 'Solving Two-Step Equations'
                }
            ]
        }
    ]
};

export default function VideoLibraryComponent({ videoGroups }) {
    const catalogs = videoGroups.slice(0, 3);
    const [activeCatalog, setActiveCatalog] = useState(videoGroups[0].title);
    console.log('🚀 ~ VideoLibraryComponent ~ activeCatalog:', activeCatalog);
    return (
        <div className='px-[70px] mt-[110px]'>
            <div className='mx-auto max-w-[1300px] bg-white rounded-[18px] mt-[32px]'>
                <div className='container mx-auto px-6 py-8 space-y-10 '>
                    <div className='flex items-center justify-between relative'>
                        <Tabs defaultValue={videoGroups[0].title} className='w-full'>
                            <TabsList className='bg-[rgba(57, 35, 112, 0.05)] gap-2'>
                                {catalogs.map((catalog) => (
                                    <TabsTrigger
                                        key={catalog.title}
                                        value={catalog.title}
                                        className='data-[state=active]:bg-[#6F5FE6] data-[state=active]:text-white rounded-full px-6 py-3 border-none font-normal'
                                        onClick={() => setActiveCatalog(catalog.title)}
                                    >
                                        {catalog.title}
                                    </TabsTrigger>
                                ))}
                            </TabsList>
                        </Tabs>
                        <div className='relative w-80'>
                            <Input
                                type='search'
                                placeholder='Search Videos'
                                className='pl-4 bg-gray-50  border-[1px] border-solid border-[rgba(57, 35, 112, 0.20)] text-[#392370] font-normal rounded-full min-h-[44px]'
                            />
                            <div className='absolute right-4 top-3 h-4 w-4 text-muted-foreground'>
                                <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M13.5233 12.9628L16.7355 16.1742L15.6742 17.2355L12.4628 14.0233C11.2678 14.9812 9.7815 15.5022 8.25 15.5C4.524 15.5 1.5 12.476 1.5 8.75C1.5 5.024 4.524 2 8.25 2C11.976 2 15 5.024 15 8.75C15.0022 10.2815 14.4812 11.7678 13.5233 12.9628ZM12.0187 12.4062C12.9704 11.4273 13.5019 10.1153 13.5 8.75C13.5 5.84975 11.1503 3.5 8.25 3.5C5.34975 3.5 3 5.84975 3 8.75C3 11.6503 5.34975 14 8.25 14C9.61528 14.0019 10.9273 13.4704 11.9062 12.5187L12.0187 12.4062Z'
                                        fill='#392370'
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className=' bg-[#D7D3E3] w-full max-w-[1240px] h-[1px] mx-auto ' />

                    {videoCategories['all-access'].map((category, index) => (
                        <div key={index} className=''>
                            <h3 className='text-xl font-bold text-[#2D1B69] my-0'>Popular Fractions Videos</h3>
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6'>
                                {videoGroups[0].videos.map((video) => (
                                    <Card key={video.slug} className='overflow-hidden'>
                                        <CardContent className='p-0'>
                                            <div className='relative aspect-video'>
                                                <Image
                                                    src={video.thumbnail.url || '/placeholder.svg'}
                                                    alt={video.title}
                                                    className='object-cover rounded-t-[16px]'
                                                    layout='fill'
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevent looping
                                                        currentTarget.src = '/placeholder.svg';
                                                    }}
                                                />
                                            </div>
                                            <div className='p-5 bg-[#6F5FE6] text-white rounded-b-[16px]'>
                                                <p className='font-medium my-0 text-sm line-clamp-1'>{video.title}</p>
                                            </div>
                                        </CardContent>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    ))}

                    <div className='text-center space-y-6'>
                        <p className='text-base font-normal text-[#1C0057] my-0 '>
                            These lessons and much more are included in the <span className='font-bold'>All-Access Math Success Plan</span>, covering 70
                            critical math topics for Grades 3-8. Try it Fee Now!
                        </p>
                        <div className='flex justify-center gap-4'>
                            <Input
                                type='email'
                                placeholder='Enter Your Email'
                                className='max-w-[300px] pl-4 py-[26px] bg-gray-50  border-[1px] border-solid border-[rgba(57, 35, 112, 0.20)] text-[#392370] font-normal rounded-full'
                            />
                            <button className='px-6 py-4 bg-[#FFD60A] text-black font-medium rounded-[50px] border-none text-base'>Try it Fee Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
