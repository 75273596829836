import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { Input } from '../../components/ui/input';
import { Button } from '../Components/Button';
import { MUZOLOGY_APP } from '@/lib/defaults';
import { beginCheckout } from '@/components/Checkout/utils/CheckoutProcess';
import { handlePreCheckout } from '@/lib/checkout_helpers/handlePreCheckout';
import { submitDuplicateAccountCheck } from '@/lib/checkout_helpers/submitDuplicateAccountCheck';

import { useAnalytics } from '@/analytics/AnalyticsProvider';
import { useForm } from 'react-hook-form';
import { checkValidEmail } from '@/lib/muzology';
import RedirectModal from '@/components/RedirectModal/RedirectModal';

const EmailSignupField = ({ id = 'email-signup', className = null, inputClassName = null, setRedirectModal, setInactiveAccount }) => {
    const router = useRouter();
    const analytics = useAnalytics();
    const [isLoading, setLoading] = useState(false);
    const defaultValues = {
        email: null,
    };

    if (process.env.NEXT_PUBLIC_FILL_TEST_DATA) {
        defaultValues.email = `test-${Date.now()}@muzology.com`;
    }
    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors }
    } = useForm({ defaultValues });


    const onSubmitBeginCheckout = useCallback(
        (data) => {
            const config = {
                data,
                router,
                analytics,
                beginCheckout,
                setLoading,
                setError,
                id
            };
            return handlePreCheckout(config);
        },
        [analytics, id, router, setError]
    );

    const onSubmitDuplicateAccountCheck = useCallback(
        async (data) => {
            const { email } = data;
            const config = {
                data,
                analytics,
                setLoading,
                setError,
                setRedirectModal,
                setInactiveAccount,
                email,
                router
            };
            
            try {
                // Call the duplicate account check which now returns a boolean success indicator
                const success = await submitDuplicateAccountCheck(config);
                
                // Only call handlePreCheckout if the duplicate check was successful
                if (success) {
                    return onSubmitBeginCheckout(data);
                }
                // If not successful, just return (the error handling is done in submitDuplicateAccountCheck)
                return false;
            } catch (error) {
                // Additional error handling if needed
                console.error('Error in duplicate account check:', error);
                return false;
            }
        },
        [analytics, setError, setRedirectModal, setInactiveAccount, router, onSubmitBeginCheckout]
    );


    return (
        <form onSubmit={handleSubmit(onSubmitDuplicateAccountCheck)} className={className}>
            <Input
                type='email'
                placeholder='Enter Your Email'
                name='email'
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                className={inputClassName ?? 'max-w-[260px] md:max-w-[300px] pl-0 md:pl-4 py-[26px] border-[1px] border-solid border-white text-white font-normal rounded-full text-center md:text-left font-Lexend text-lg'}
                style={{ background: 'rgba(255, 255, 255, 0.15)' }}
                // required
                {...register('email', { required: true, validate: { validEmail: checkValidEmail } })}
            />
            <div className='w-full max-w-[260px] md:max-w-[180px]'>
                <Button type='submit' variant='primary' className='w-full md:max-w-[180px] px-6 py-4 text-lg'>
                    Get Started
                </Button>
            </div>

        </form>
    );
};

export default EmailSignupField;
