import React from 'react';
import { Image } from 'react-datocms';
import { Button, H3, Text } from 'atoms';
import { Fade } from "react-awesome-reveal";
import BalanceText from '@/components/BalanceText/BalanceText';
import { useDispatchUi } from 'context/ui';

export const HowItWorks = ({ heading, subhead, img, richText, flip, ctaButton, videoLink, style = null }) => {
    const dispatch: any = useDispatchUi();
    return (
        <div id='container' style={style}>
            <Fade>
                <div id='content'>
                    <div id='heading'>
                        <H3 mb={4} style={{ color: '#121651' }}>
                            <BalanceText>{heading}</BalanceText>
                        </H3>
                        <Text mb={4} style={{ color: '#4f4f4f', fontSize: '18px' }}>
                            <BalanceText>{subhead}</BalanceText>
                        </Text>
                    </div>
                    <div id='bottom'>
                        <div dangerouslySetInnerHTML={{ __html: richText }} />
                        <div id='ghost-inspector-hide' className='ghost-inspector-hide'>
                            <Image
                                className='ghost-inspector-hide'
                                pictureClassName='ghost-inspector-hide'
                                data={{ ...img.responsiveImage, bgColor: '#ffffff00' }}
                            />
                            {/*
							  <Image
								height={img.height}
								width={img.width}
								src={img.url}
								loading='lazy'
							  />
		              */}
                        </div>
                    </div>
                </div>
                <>
                    {ctaButton && (
                        <div id='cta-button'>
                            <Button
                                ctaVariant='secondary'
                                onClick={() => dispatch({ type: 'PLAY_VIDEO', payload: videoLink })}
                            >
                                Learn Math in a MINUTE
                            </Button>
                        </div>
                    )}
                </>
            </Fade>
            <style jsx>{`
                #container {
                    background-color: #f9f9f9;
                    padding: 48px 60px;
                }

                #content {
                    max-width: 1100px;
                    margin: 0 auto;
                }

                #heading {
                    text-align: center;
                }

                #bottom {
                    color: #4f4f4f;
                    font-family: Avenir, 'Avenir', sans-serf, serif;
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: ${flip ? 'row-reverse' : 'row'};

                    > div {
                        width: 100%;
                        padding: 8px;
                    }

                    ul {
                        margin: 24px 0;
                        list-style-image: url('/assets/icons/red-bullet.svg');

                        li {
                            margin: 12px 0;
                            padding-left: 8px;
                        }
                    }
                }

                #cta-button {
                    max-width: 1100px;
                    margin: 0 auto;
                    margin-top: -40px;
                }

                @media (max-width: 992px) {
                    #container {
                        padding: 48px 40px;
                    }

                    #bottom {
                        flex-direction: column;

                        > div {
                            padding: 0px;
                        }
                    }

                    #cta-button {
                        margin-top: 20px;
                    }
                }

                @media (max-width: 480px) {
                    #container {
                        padding: 48px 20px;
                    }
                }
            `}</style>
        </div>
    );
};
