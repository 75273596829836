import { useState, useRef, useEffect } from 'react';
import VideoPlayerHeroImage from '@/assets/homepageV2/hero/VideoPlayerHero2.png';
import VideoPlayerHeroMobileImage from '@/assets/homepageV2/hero/Hero_Mobile_Thumbnail.png';
import Image from 'next/image';
import IntersectionObserverElement from '@/components/IntersectionObserverElement/IntersectionObserverElement';
import { useMedia } from 'react-use';
import EmailSignupField from '@/components/HomePageV2/EmailSignupField/EmailSignupField';
import VimeoPlayer from '@/components/VimeoPlayer/VimeoPlayer';

export default function HeroVideoPlayer({ setRedirectModal, setInactiveAccount }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [sectionVisible, setSectionVisible] = useState<any | null>(null);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const playerRef = useRef<any>(null);
    const isMobile = useMedia('(max-width: 767px)', false);

    useEffect(() => {
        if (!isMobile) {
            // When the page loads, start a 3-second timer
            const timer = setTimeout(() => {
                setIsPlaying(true);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [isMobile]);

    useEffect(() => {
        if (!sectionVisible && playerRef.current && isVideoReady) {
            playerRef.current.pause();
        }
    }, [sectionVisible, isVideoReady, isPlaying]);

    const handlePlayerReady = (player: any) => {
        playerRef.current = player;
        setIsVideoReady(true);
    };

    // mobile logic
    useEffect(() => {
        // When the section becomes visible, start a 3-second timer
        if (sectionVisible && isMobile) {
            const timer = setTimeout(() => {
                if (playerRef.current) {
                    playerRef.current.play();
                }
            }, 3000);

            return () => clearTimeout(timer);
        }
        if (!sectionVisible && playerRef.current) {
            playerRef.current.pause();
        }
    }, [sectionVisible, isMobile]);

    return (
        <header className='relative py-12 pb-0 lg:pb-8 pt-28 md:pt-40 px-4 md:px-10 lg:px-12 xl:px-[70px]'>
            {/* blur effect pink */}
            <div className='hidden md:block absolute top-[-50px] right-0 rounded-full opacity-[1.4] bg-[#B031FA] bg-opacity-[0.7] blur-[250px] w-[729px] h-[585px] z-[1]' />
            {/* blur effect dark purple */}
            <div className='hidden md:block absolute top-[-50px] left-0 rounded-full opacity-[1.4] bg-[#161042] bg-opacity-[0.7] blur-[250px] w-[729px] h-[585px] z-[1]' />
            <div className='container grid lg:grid-cols-2 xl:gap-0  max-w-[1300px] mx-auto z-[2]'>
                <div className='max-w-full lg:max-w-[550px] z-[2]'>
                    <div className=''>
                        <h1 className='font-normal text-white text-[42px] sm:text-[69px] xl:text-[76px] my-0 text-center lg:text-left leading-[3rem] md:leading-[4rem] '>
                            Math like <br />
                            <span className='text-[#64DFE5] font-Gloria leading-6 sm:leading-[100px] md:leading-[4.5rem]'>never before</span>
                        </h1>

                        <p className='hidden lg:block max-w-[460px] text-white md:text-xl md:leading-[1.85rem]  '>
                            Muzology&apos;s award-winning <span className='text-[#64DFE5]'>math music videos</span> and interactive quizzes make learning math
                            fast, fun and easy.
                        </p>
                    </div>
                    <div className='hidden lg:block mt-8'>
                        <EmailSignupField
                            className='flex justify-start gap-4 mt-6'
                            setRedirectModal={setRedirectModal}
                            setInactiveAccount={setInactiveAccount}
                        />
                        <p className='hidden text-base text-gray-400 my-0 ml-4 mt-0'>No credit card required.</p>
                    </div>
                </div>
                <div className='relative hidden md:block '>
                    {!isPlaying ? (
                        <div
                            className='z-[2] xl:-mt-10 ml-6 lg:ml-14 xl:ml-0 lg:mr-14 xl:mr-0 relative xl:absolute xl:right-[-40px] w-full xl:w-[650px] hero-video:w-[700px] cursor-pointer'
                            onClick={() => setIsPlaying(true)}
                        >
                            <Image src={VideoPlayerHeroImage} alt='Math learning preview' className=' w-full h-auto z-[2] object-cover' />
                        </div>
                    ) : (
                        <div className='ghost-inspector-hide relative lg:absolute inset-0 z-[2] mt-5 lg:mt-0'>
                            {!isVideoReady && (
                                <div className='xl:-mt-10 ml-6 lg:ml-14 xl:ml-0 lg:mr-14 xl:mr-0 relative xl:absolute xl:right-[-40px] w-full xl:w-[650px] hero-video:w-[700px] cursor-pointer'>
                                    <Image src={VideoPlayerHeroImage} alt='Math learning preview' className='w-full h-auto object-cover' />
                                </div>
                            )}
                            <VimeoPlayer videoId='1052420726' onReady={handlePlayerReady} />
                        </div>
                    )}
                </div>
                <div className='ghost-inspector-hide relative block md:hidden mt-6 sm:mt-0'>
                    <VimeoPlayer
                        videoId='1052420726'
                        onReady={(player) => {
                            playerRef.current = player;
                        }}
                    />
                </div>
                <div className='block lg:hidden relative z-[2]'>
                    <p className=' text-white text-base md:text-xl mt-3.5 mb-0 text-center '>
                        Muzology&apos;s award-winning <span className='text-[#64DFE5]'>math music videos</span> and interactive quizzes make learning math fast,
                        fun and easy.
                    </p>
                </div>

                <div className='block lg:hidden relative z-[2]'>
                    <EmailSignupField
                        id='email-signup'
                        className='flex-col md:flex-row  flex justify-center items-center gap-4 mt-4'
                        inputClassName='text-[18px] max-w-[260px] md:max-w-[300px] pl-0 md:pl-4 py-[26px]  border-[1px] border-solid border-white text-white font-normal rounded-full text-center md:text-left'
                        setRedirectModal={setRedirectModal}
                        setInactiveAccount={setInactiveAccount}
                    />
                    <div className='max-w-full md:max-w-[430px] mx-auto'>
                        <p className='hidden text-base text-gray-400 my-0 ml-0 mt-2 text-center md:text-left'>No credit card required.</p>
                    </div>
                </div>
            </div>
            <IntersectionObserverElement updateElementIsVisible={setSectionVisible} classNames='' ref={elementRef} />
        </header>
    );
}
