import React from 'react';
import Image from 'next/legacy/image';
import Ipad from '../../public/assets/graphics/ipad-background-no-shadow.png';
import IpadGif from '../../public/assets/graphics/medley.gif';
import { DISABLE_GIFS } from '@/lib/defaults';
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

const IpadMedley = (props) => {
    return (
        <div className={'relative ' + props.className} style={{
            boxShadow: '10.0341px 25.0853px 25.0853px rgba(0, 0, 0, 0.25)' }} aria-hidden='true'>
            <Image src={Ipad} priority={true} alt='iPad' />
            {/* <div className='absolute w-[53.71%] top-[22.65%] left-[23.64%] right-[19.68%] bottom-[42.4%] flex items-stretch justify-center bg-[#242725] bg-black'> */}
            <div className='absolute top-[14.5%] left-[17%] w-[67%] h-[44.5%] flex items-stretch justify-center'>
                {DISABLE_GIFS ? (
                    <div className='bg-green-400 absolute inset-0'>
                        <div className='m-auto h-full w-full flex items-center justify-center'>
                        </div>
                    </div>
                ) : (
                    <div className='ghost-inspector-hide aspect-video h-full bg-black'>
                        <video autoPlay muted loop playsInline height='100%'>
                            <source src='/assets/graphics/medley.mp4' type='video/mp4' />
                        </video>
                    </div>
                    /*
                    <Image
                        className='ghost-inspector-hide'
                        src={IpadGif}
                        objectFit='fill'
                        priority={true}
                    />
                    */
                )}
            </div>
        </div>
    );
};

export default IpadMedley;
