import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious, type CarouselApi } from '@/components/components/ui/carousel';
import { TestimonialCard } from './TestimonialCard';
import React from 'react';
import { useMedia } from 'react-use';
import { Input } from '@/components/components/ui/input';
import { Button } from '@/components/HomePageV2/Components/Button';
import EmailSignupField from '@/components/HomePageV2/EmailSignupField/EmailSignupField';

export default function TestimonialsSection({ testimonials, setRedirectModal, setInactiveAccount }) {
    const [api, setApi] = React.useState<CarouselApi>();
    const [current, setCurrent] = React.useState(0);
    const isMedium = useMedia('(max-width: 1023px)', false);
    const isMobile = useMedia('(max-width: 767px)', false);

    const getCardsPerGroup = () => {
        if (isMobile) return 1;
        if (isMedium) return 2;
        return 3;
    };

    const getVisibleTestimonials = () => {
        if (isMobile) {
            return testimonials.slice(0, testimonials.length - 3);
        }
        if (isMedium) {
            return testimonials.slice(0, testimonials.length - 1);
        }
        return testimonials;
    };

    const visibleTestimonials = getVisibleTestimonials();

    React.useEffect(() => {
        if (!api) return;

        api.scrollTo(current);
        // Update current index when the carousel scrolls
        api.on('select', () => {
            setCurrent(api.selectedScrollSnap());
        });
    }, [api, current]);

    const goToGroup = (groupIndex: number) => {
        setCurrent(groupIndex * getCardsPerGroup());
    };

    const totalGroups = Math.floor(visibleTestimonials.length / getCardsPerGroup());

    return (
        <section className='pt-10 md:pt-20 pb-20   px-4 md:px-10 lg:px-12 lx:px-[70px]'>
            <div className='max-w-[1300px] mx-auto'>
                <h2 className='text-center md:mb-12 mt-0 md:mt-10 text-[40px] leading-[2.8rem] sm:leading-[3.1rem] md:text-5xl font-normal'>
                    <div className='text-white max-w-full mx-auto'>Educators, students and parents agree.</div>
                    <div className='mt-2 max-w-full mx-auto'>
                        <span className='text-white'>Muzology </span>
                        <span className='text-[#14B5BD] font-Gloria'>makes math</span>
                        <span className='text-white'> irresistible </span>
                        <span className='text-[#F11581] font-Gloria'>for kids</span>
                        <span className='text-white'>.</span>
                    </div>
                </h2>

                <Carousel className='w-full max-w-[1300px]mx-auto' setApi={setApi}>
                    <CarouselContent className='-ml-4'>
                        {visibleTestimonials.map((testimonial, index) => (
                            <CarouselItem key={index} className='pl-4 md:basis-1/2 lg:basis-1/3'>
                                <TestimonialCard {...testimonial} />
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                    <div className='flex justify-center mt-8 gap-4 sm:gap-6'>
                        {[...Array(totalGroups)].map((_, index) => (
                            <button
                                key={index}
                                onClick={() => goToGroup(index)}
                                className={`w-[24px] h-[24px] rounded-full border-none ${
                                    Math.floor(current / getCardsPerGroup()) === index ? 'bg-[#1CC8E1]' : ' bg-[#E0C8F9]'
                                }`}
                                aria-label={`Go to slides ${index * getCardsPerGroup() + 1}-${Math.min(
                                    (index + 1) * getCardsPerGroup(),
                                    visibleTestimonials.length
                                )}`}
                            />
                        ))}
                    </div>
                </Carousel>
                <div className=' mt-14'>
                    {/* Email capture  */}
                    <EmailSignupField
                        setRedirectModal={setRedirectModal}
                        setInactiveAccount={setInactiveAccount}
                        className='flex flex-col md:flex-row justify-center items-center gap-4  '
                        inputClassName='text-[18px] w-full max-w-[260px] md:max-w-[280px] xl:max-w-[300px] pl-0 md:pl-4 py-[26px]  border-[1px] border-solid border-white text-white font-normal rounded-full text-center md:text-left'
                    />
                    <div className='max-w-full md:max-w-[455px] lg:max-w-[465px] mx-auto'>
                        <p className='hidden text-base text-gray-400 my-0 ml-0 mt-2 text-center md:text-left'>No credit card required.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
