import Image from 'next/legacy/image';
import { Input } from '@/components/components/ui/input';
import GirlCta from '@/assets/homepageV2/GirlCtaSection/GirlCta.jpg';
import GirlCtaMobile from '@/assets/homepageV2/GirlCtaSection/GirlCta-Mobile.jpg';
import { Button } from '@/components/HomePageV2/Components/Button';
import EmailSignupField from '@/components/HomePageV2/EmailSignupField/EmailSignupField';

export default function GirlCtaSection({ setRedirectModal, setInactiveAccount }) {
    return (
        <section className=' text-white mt-20 md:mt-[110px]'>
            <div className='max-w-[1165px] mx-auto px-8 md:px-10  '>
                <h1 className='mb-5 font-medium leading-12 sm:leading-tight text-[40px] md:text-5xl lg:text-[50px] text-center block lg:hidden'>
                    A Smarter Way <span className='block font-normal font-Gloria text-[#40E0D0]'>to Learn</span>
                </h1>
                <div className='flex flex-col lg:flex-row  items-center justify-center  sm:gap-14 lg:grid-cols-2'>
                    {/* Left Column - Image */}
                    <div className='relative max-w-[242px] sm:max-w-[400px] hidden sm:block'>
                        <Image src={GirlCta} alt='Student learning with headphones' className='rounded-full object-cover max-w-full ' priority />
                    </div>
                    {/* Left Column - Image Mobile*/}
                    <div className='relative max-w-[242px] block sm:hidden'>
                        <Image src={GirlCtaMobile} alt='Student learning with headphones' className='rounded-full object-cover max-w-full ' priority />
                    </div>
                    {/* Right Column - Content */}
                    <div className='space-y-8 relative z-[2]'>
                        <div className=''>
                            <h1 className=' font-medium leading-12 sm:leading-tight tracking-tighter text-[40px] md:text-5xl lg:text-[50px] xl:text-6xl hidden lg:block'>
                                A Smarter Way <span className='block font-normal font-Gloria text-[#40E0D0]'>to Learn</span>
                            </h1>

                            <div className='space-y-3 sm:space-y-6'>
                                <div>
                                    <h2 className='mb-2 text-xl md:text-2xl font-normal '>• Learn math in 15-minute sessions</h2>
                                </div>

                                <div>
                                    <h2 className='mb-2 text-xl md:text-2xl font-normal '>• Progress at your own pace</h2>
                                </div>

                                <div>
                                    <h2 className='mb-2 text-xl md:text-2xl font-normal '>• Aligned to state and national math standards </h2>
                                </div>
                            </div>

                            <div className='mt-10 relative z-[2] hidden sm:block'>
                                <div></div>
                                <EmailSignupField
                                    className='flex justify-start gap-4  '
                                    inputClassName='max-w-[280px] xl:max-w-[300px] pl-4 py-[26px]  border-[1px] border-solid border-white text-white font-normal rounded-full text-center md:text-left'
                                    setRedirectModal={setRedirectModal}
                                    setInactiveAccount={setInactiveAccount}
                                />

                            </div>
                            <p className='hidden text-base text-gray-400 my-0 ml-4 mt-2'>No credit card required.</p>
                        </div>
                    </div>
                </div>
                <div className='block sm:hidden relative z-[2] mt-10'>
                    <EmailSignupField
                        className='flex-col flex justify-center items-center gap-4 mt-4'
                        inputClassName='text-[18px] w-full max-w-[260px] md:max-w-full pl-0 md:pl-4 py-[26px]  border-[1px] border-solid border-white text-white font-normal rounded-full text-center md:text-left'
                        setRedirectModal={setRedirectModal}
                        setInactiveAccount={setInactiveAccount}
                    />
                    <div className='max-w-full mx-auto'>
                        <p className='hidden text-base text-gray-400 my-0 ml-0 mt-2 text-center md:text-left'>No credit card required.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
